import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { addDoc, collection } from "firebase/firestore";
import { db } from "./firebaseConfig"; // Ensure correct path to your firebase config

import "./GetQuoteModal.css";

const GetQuoteModal = () => {
    const navigate = useNavigate();
    const { pathname, ...location } = useLocation();
    const { from } = location.state || { from: { pathname: "/" } };

    const [showModal, setShowModal] = useState(false);
    const { register, handleSubmit } = useForm();

    // Open modal if the path matches `/getquote`
    useEffect(() => {
        if (pathname === "/getquote") {
            setShowModal(true);
        }
    }, [pathname]);

    // Submit handler for Firestore integration
    const onSubmit = async (data) => {
        toast.loading("Submitting your request...");

        try {
            // Add data to Firestore
            const docRef = await addDoc(collection(db, "quotes"), data);
            toast.dismiss();
            toast.success("Quote request submitted successfully!");
            console.log("Document written with ID: ", docRef.id);

            // Close modal and navigate
            setShowModal(false);
            navigate("/"); // Redirect to home or thank-you page
        } catch (e) {
            toast.dismiss();
            toast.error("Failed to submit request. Please try again.");
            console.error("Error adding document: ", e);
        }
    };

    return (
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
                navigate("/"); // Redirect to home
            }}
            size="lg"
            centered
        >
            <Modal.Header closeButton />
            <Modal.Body>
                <div className="quote-container">
                    <div className="form get-quote">
                        <h2>Get a Quote</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label>
                                <span>
                                    Name <span className="required">*</span>
                                </span>
                                <input
                                    {...register("name", { required: true })}
                                    type="text"
                                    placeholder="Your Name"
                                />
                            </label>
                            <label>
                                <span>
                                    Email <span className="required">*</span>
                                </span>
                                <input
                                    {...register("email", { required: true })}
                                    type="email"
                                    placeholder="Your Email"
                                />
                            </label>
                            <label>
                                <span>
                                    Phone <span className="required">*</span>
                                </span>
                                <input
                                    {...register("phone", { required: true })}
                                    type="tel"
                                    placeholder="e.g., +1 (Your Phone Number)"
                                />
                            </label>
                            <label>
                                <span>Company</span>
                                <input
                                    {...register("company")}
                                    type="text"
                                    placeholder="Company"
                                />
                            </label>
                            <label>
                                <span>
                                    Service Required <span className="required">*</span>
                                </span>
                                <select {...register("service", { required: true })}>
                                    <option value="">Select an option</option>
                                    <option value="analytics-insights">
                                        Analytics and Insights
                                    </option>
                                    <option value="career-development">
                                        Career Development
                                    </option>
                                    <option value="corporate-branding">
                                        Corporate Branding
                                    </option>
                                    <option value="personal-branding">
                                        Personal Branding
                                    </option>
                                    <option value="pitching-guidance">
                                        Pitching Guidance
                                    </option>
                                    <option value="project-showcase">
                                        Project Showcase
                                    </option>
                                </select>
                            </label>
                            <label>
                                <span>How soon are you ready to start? <span className="required">*</span></span>
                                <select {...register("readyToStart", { required: true })}>
                                    <option value="">Select an option</option>
                                    <option value="immediately">Immediately</option>
                                    <option value="1-week">Within a week</option>
                                    <option value="2-weeks">Within 2 weeks</option>
                                    <option value="1-month">Within a month</option>
                                    <option value="not-sure">Not sure yet</option>
                                </select>
                            </label>
                            <label>
                                <span>Additional Details</span>
                                <textarea
                                    {...register("details")}
                                    placeholder="Provide any additional information..."
                                />
                            </label>
                            <button type="submit" className="submit">
                                Submit Request
                            </button>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default GetQuoteModal;

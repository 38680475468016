import React, { createContext, lazy, Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "./App.css";
import LoadingSpinner from "./components/Home/LoadingSpinner/LoadingSpinner";
import GetQuoteModal from "./components/Quote/GetQuoteModal";

const Home = lazy(() => import("./pages/Home"));
const PrivacyPolicy = lazy(()=> import("./pages/Privacy") )
const TermsOfService = lazy(() => import("./pages/Terms"))

// Create a context for selected services
export const AppContext = createContext();

function App() {
    const [selectedService, setSelectedService] = useState([]);
    const location = useLocation(); // Get current route location

    return (
        <AppContext.Provider value={{ selectedService, setSelectedService }}>
            <Toaster />
            <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
                    <Route path="/terms-of-service" element={<TermsOfService />} /> 
                </Routes>
                {/* Conditionally render the Quote Modal based on the route */}
                {location.pathname === "/getquote" && <GetQuoteModal />}
            </Suspense>
        </AppContext.Provider>
    );
}

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default AppWrapper;

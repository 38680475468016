import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCO1O7Ph_zDFEjS2tMkkMRItKkjR0Si7GU",
    authDomain: "dropaxa-61c79.firebaseapp.com",
    projectId: "dropaxa-61c79",
    storageBucket: "dropaxa-61c79.firebasestorage.app",
    messagingSenderId: "118622745619",
    appId: "1:118622745619:web:dd7ac4cdaa685057c8fbe6",
    measurementId: "G-0ZN624KVX8"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
